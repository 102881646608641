<template>
  <div class="email-manager">
    <BreadCrumb
        v-if="$route.query.parent === 'app' || $route.query.parent === 'tp'"
        :links="$route.query.parent === 'app' ?
      [
        {
          name: 'Application Focus',
          params: {
            uuid: getApplicationUuid
          }
        },
        {
          name: getApplicationFullName + ' Email Send Interface'
        }
      ] :
      [
        {
          name: 'Application Focus',
          params: {
            uuid: getApplicationUuid
          }
        },
        {
          name: 'Traced Pension',
          params: {
            uuid: getApplicationUuid,
            tpuuid: $route.params.tpuuid
          }
        },
        {
          name: getApplicationFullName + ' Email Send Interface'
        }
      ]
      "
    />
    <BreadCrumb
        v-else
        :links="[
        {
          name: 'Email Manager'
        },
        {
          name: 'Email Thread Viewer',
          params: {
            uuid: $route.params.tpuuid
          }
        },
        {
          name:
            'Send Email Interface'
        }
      ]"
    />
    <div class="card">
      <div class="card-body">
        <EMSubMenu ref="EMSubMenu" @selected="handleSubMenuSelected"/>
        <EMDocumentSelect v-if="subMenuSelected === 'send-document'"/>
        <div v-else-if="subMenuSelected === 'send-email' || computedTemplate !== null" class="row">
          <div
              v-if="computedOnlyBlankTemplate == 'false'"
              class="row col-12 text-left align-items-center justify-items-start"
          >
            <div class="col-3 text-left">
              <h5>Select email template:</h5>
            </div>
            <div class="col-9 text-left align-items-center">
              <SelectInput
                  :options="filterTemplateList"
                  :value="activeTemplate"
                  :with-blank-option="true"
                  class="confirmationYes"
                  label=""
                  name="email_template"
                  @selected="handleSelected"
              />
            </div>
          </div>
        </div>
        <div v-if="activeTemplate || computedTemplate" class="row">
          <div :class="[colStyleLeft]">
            <Form
                id="dataForm"
                :validation-schema="schema"
                class="w-100 text-left"
                @submit="handleUpdate"
            >
              <button class="fabric-btn fabric-btn-submit mt-0 mb-4">
                Update
              </button>
              <div v-if="bespokeContentInputFields">
                <TextInput
                    ref="sendToInput"
                    :value="replyToEmail || setDefaultEmail"
                    label="Send to"
                    name="email"
                    type="text"
                    @updated="handleSendToUpdate"
                />
                <TextInput label="Title" name="emailTitle" type="text"/>
                <TextInput
                    :value="evalutateProperty('getApplication?.first_name')"
                    label="Name"
                    name="firstname"
                    type="text"
                />
                <TextArea
                    label="Email content"
                    name="emailContent"
                    placeholder=""
                    type="text"
                />
              </div>
              <div v-if="bespokeInputFields">
                <TextInput
                    label="Policy name or reference"
                    name="policyNumber"
                    placeholder=""
                    type="text"
                />
                <TextInput
                    label="Provider"
                    name="providerName"
                    placeholder=""
                    type="text"
                />
                <TextInput
                    label="Employer"
                    name="employer"
                    placeholder=""
                    type="text"
                />
              </div>
              <div v-if="dateInput">
                <DatePicker
                    :value="dobDate"
                    label="Date of comms"
                    name="contactDate"
                    placeholder=""
                />
              </div>
              <div v-if="confirmRetirementIncomeInput">
                <SelectInput
                    :options="retirementIncomeInputSelect"
                    label="Please select an option"
                    name="retirementIncome"
                />
              </div>
              <div v-if="confirmTransferInput">
                <SelectInput
                    :options="confirmTransferSelect"
                    label="Please select an option"
                    name="confirmTransfer"
                />
              </div>
              <div v-if="confirmProviderEmailSelect">
                <SelectInput
                    :options="confirmEmailSelect"
                    label="Please select an option"
                    name="providerEmailResponse"
                />
              </div>
              <div v-if="bespokeEmployerInput">
                <TextInput
                    label="Employer"
                    name="employer"
                    placeholder=""
                    type="text"
                />
              </div>
              <div v-if="bespokeNewProviderInput">
                <TextInput
                    label="New Provider"
                    name="newProvider"
                    placeholder=""
                    type="text"
                />
              </div>
              <div v-if="employerInput">
                <SelectInput
                    :options="employmentSelect"
                    label="Employment"
                    name="employment"
                />
              </div>
              <div v-if="loaRejectedInput">
                <h5>Select rejection reasons:</h5>
                <CheckInput
                    label="The address on the pension is different to any of the addresses you’ve provided."
                    name="rejectedReason"
                    value="The address on the pension is different to any of the addresses you’ve provided."
                />
                <CheckInput
                    label="They have a pension that could be yours but is under a different surname."
                    name="rejectedReason"
                    value="They have a pension that could be yours but is under a different surname."
                />
                <CheckInput
                    label="They’ve found a match with the name, address and National Insurance number, but the date of birth is different."
                    name="rejectedReason"
                    value="They’ve found a match with the name, address and National Insurance number, but the date of birth is different."
                />
                <CheckInput
                    label="They’ve found a match with the name, address and date of birth, but the National Insurance number is different."
                    name="rejectedReason"
                    value="They’ve found a match with the name, address and date of birth, but the National Insurance number is different."
                />
                <CheckInput
                    label="They have a pension that could be yours but has a different policy number."
                    name="rejectedReason"
                    value="They have a pension that could be yours but has a different policy number."
                />
                <TextInput
                    label="Optional text"
                    name="optionalText"
                    placeholder=""
                    type="text"
                />
              </div>
              <div v-if="missingContentInput">
                <SelectInput
                    :options="[
                    {
                      id: 'yes',
                      name: 'Yes'
                    },
                    {
                      id: 'no',
                      name: 'No'
                    }
                  ]"
                    label="Employment history required?"
                    name="employmentHistory"
                    @selected="showPlaceEmpHistory"
                />
                <div v-if="showPEH" class="sub-menu">
                  <TextArea
                      :value="employmentPlaces"
                      class="pr-1"
                      label="Places of employment ( separated by commas )"
                      name="employmentHistoryJobs"
                      rows="2"
                      type="text"
                  />
                </div>
                <SelectInput
                    :options="[
                    {
                      id: 'yes',
                      name: 'Yes'
                    },
                    {
                      id: 'no',
                      name: 'No'
                    }
                  ]"
                    label="Employment dates required?"
                    name="employmentDates"
                    @selected="showEmpDates"
                />
                <div v-if="showED" class="sub-menu">
                  <TextArea
                      :value="employmentDates"
                      class="pr-1"
                      label="Places of employment ( separated by commas )"
                      name="employmentDatesJobs"
                      rows="2"
                      type="text"
                  />
                </div>
                <SelectInput
                    :options="[
                    {
                      id: 'yes',
                      name: 'Yes'
                    },
                    {
                      id: 'no',
                      name: 'No'
                    }
                  ]"
                    label="Maiden name required?"
                    name="maidenNames"
                />
                <SelectInput
                    :options="[
                    {
                      id: 'yes',
                      name: 'Yes'
                    },
                    {
                      id: 'no',
                      name: 'No'
                    }
                  ]"
                    label="More employment information required?"
                    name="employerMoreInfo"
                    @selected="showEmpInfo"
                />
                <div v-if="showEI" class="sub-menu">
                  <TextArea
                      :value="employmentMoreInfo"
                      class="pr-1"
                      label="Places of employment ( separated by commas )"
                      name="employmentMoreInfoJobs"
                      rows="2"
                      type="text"
                  />
                </div>
              </div>
              <TextInput
                  v-if="bespokeEmail"
                  ref="sendToInput"
                  label="Send to"
                  name="email"
                  type="text"
                  @updated="handleSendToUpdate"
              />
              <TextInput
                  v-if="attachmentPasswordInput"
                  label="Attachment Password"
                  name="attachmentPassword"
                  placeholder=""
                  type="password"
              />
            </Form>
          </div>
          <div v-if="activeTemplate !== 'automateLetterGeneration'" :class="[colStyleRight]">
            <div class="row">
              <div class="col-12 mb-4">
                <button
                    :disabled="disableSend || showDocumentError"
                    class="fabric-btn fabric-btn-submit mt-0"
                    @click="handleSend"
                >
                  Send
                </button>
              </div>
            </div>
            <div><strong>Title:</strong> {{ emailSubject }}</div>
            <br>
            <iframe
                id="emailIframe"
                ref="emailIframe"
                frameborder="0"
                style="background: #FFFFFF;"
            ></iframe>
            <div class="row">
              <div class="col-12">
                <div>
                  <p v-show="showDocumentError" class="help-message">
                    Required documents not available {{ unavailableDocTypes }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import {mapGetters, mapActions, mapMutations} from "vuex";
import EMDocumentSelect from "@/components/email-manager/EMDocumentSelect";
import EMSubMenu from "@/components/email-manager/EMSubMenu";

export default {
  name: "EmailManager",
  components: {
    EMDocumentSelect,
    EMSubMenu,
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().email().nullable(),
      employment: Yup.string().nullable(),
      employmentHistory: Yup.string().nullable()
    });
    return {
      schema
    };
  },
  data: () => ({
    emailSubject: "",
    subMenuSelected: null,
    activeTemplate: null,
    employmentPlaces: null,
    employmentDates: null,
    employmentMoreInfo: null,
    sendTo: null,
    unavailableDocTypes: [],
    wetSigFilter: [],
    showDocumentError: false,
    disableSend: false,
    showPEH: false,
    showED: false,
    showEI: false,
    retirementIncomeInputSelect: [
      {
        id: "retirementProduct",
        name:
            "When we got in touch with your provider, they confirmed you’ve used this pension to take an income."
      },
      {
        id: "cashedOut",
        name:
            "When we got in touch with your provider, they confirmed you took all the money from this pension as a cash lump sum."
      }
    ],
    confirmTransferSelect: [
      {
        id: "providerDetailsReceivedDigital",
        name:
            "If new provider details received (digital signature/already contacted)"
      },
      {
        id: "providerDetailsReceivedWet",
        name: "If new provider details received (wet signature)"
      },
      {
        id: "transferOutToAviva",
        name: "Transfer out to Aviva"
      },
    ],
    confirmEmailSelect: [
      {
        id: "email",
        name: "Provider sends response via email"
      },
      {
        id: "attachment",
        name: "Provider sends response via attachment"
      }
    ],
    availableFileTypes: []
  }),
  computed: {
    ...mapGetters([
      "getEmployments",
      "getApplication",
      "getApplicationUuid",
      "getFiles",
      "getEmailTemplates",
      "getTracedPension",
      "getFiles",
      "getApplicationFullName"
    ]),
    replyToEmail() {
      if (this.activeTemplate === "pensionReportEmailQA") return null;
      return this.$route.query.replyEmail || null;
    },
    computedTemplate() {
      return this.$route.query.template;
    },
    computedOnlyBlankTemplate() {
      return this.$route.query.onlyBlankTemplate || "false";
    },
    filterTemplateList() {
      var tempArray = this.getEmailTemplates;

      if (this.getApplication.source === 'pcs') {
        tempArray = tempArray.filter(et => {
          if (
              et.id !== "pensionReportEmail"
          ) {
            return et;
          }
        });
      }

      if (this.getApplication.source === 'pds') {
        tempArray = tempArray.filter(et => {
          if (
              et.id !== "template20_pcsLORTemplate" &&
              et.id !== "oisPlatform"
          ) {
            return et;
          }
        });
      }

      if (this.$route.query.parent === "emailapp" || this.$route.query.parent === "app") {
        return tempArray.filter(et => {
          if (
              et.id === "blankTemplate" ||
              et.id === "applicationReceivedSuccessfully" ||
              et.id === "serviceCompleteDefinedBenefitsPolicyFound" ||
              et.id === "serviceCompleteSuccessful" ||
              et.id === "serviceCompleteSuccessful" ||
              et.id === "signatureWordingUpdate" ||
              et.id === "signatureMissingFromApplicationChaser"
          ) {
            return et;
          }
        });
      }

      return tempArray.filter(et => {
        if (
            et.id !== "serviceCompleteDefinedBenefitsPolicyFound" &&
            et.id !== "serviceCompleteSuccessful" &&
            et.id !== "serviceCompleteUnsuccessful" &&
            et.id !== "signatureWordingUpdate" &&
            et.id !== "signatureMissingFromApplicationChaser"
        ) {
          return et;
        }
      });
    },
    bespokeEmployerInput() {
      let bespokeEmployerInputArray = [
        "definedBenefits",
        "definedBenefitsMarketing",
        "noResponseTwoMonths",
        "pensionReportEmail",
        "providerConfirmedTransferOut",
        "providerConfirmedRetirementIncomeOrFullCash",
        "providerUnableToFindPolicy"
      ];

      return bespokeEmployerInputArray.includes(this.activeTemplate);
    },
    bespokeNewProviderInput() {
      let bespokeNewProviderInputArray = ["providerConfirmedTransferOut"];

      return bespokeNewProviderInputArray.includes(this.activeTemplate);
    },
    bespokeContentInputFields() {
      let bespokeInputArray = ["blankTemplate", "pensionReportEmailQA"];

      return bespokeInputArray.includes(this.activeTemplate);
    },
    bespokeInputFields() {
      let bespokeInputArray = ["searchUnsuccessful"];

      return bespokeInputArray.includes(this.activeTemplate);
    },
    confirmRetirementIncomeInput() {
      let confirmRetirementArray = [
        "providerConfirmedRetirementIncomeOrFullCash"
      ];

      return confirmRetirementArray.includes(this.activeTemplate);
    },
    confirmTransferInput() {
      let confirmTransferArray = ["providerConfirmedTransferOut"];

      return confirmTransferArray.includes(this.activeTemplate);
    },
    confirmProviderEmailSelect() {
      let confirmProviderEmailArray = [
        "definedBenefits",
        "definedBenefitsMarketing"
      ];

      return confirmProviderEmailArray.includes(this.activeTemplate);
    },
    loaRejectedInput() {
      let loaRejectedArray = [
        "loaRejectedAvivaResolve",
        "loaRejectedContactProvider"
      ];

      return loaRejectedArray.includes(this.activeTemplate);
    },
    employerInput() {
      let empTemplateArray = [
        // "definedBenefits"
        // "definedBenefitsMarketing",
        // "loaCoveringEmailDataRequestToProviderUnknown"
        // "noResponseTwoMonths",
        // "providerUnableToFindPolicy",
        // "searchUnsuccessful"
      ];

      return empTemplateArray.includes(this.activeTemplate);
    },
    missingContentInput() {
      let missingTemplateArray = [
        // "missingInformation",
        // "missingInformationChaser",
        // "missingInformationChaserClosed",
        "missingInformationReceived",
        "missingInformationStillRequiredClosed",
        "missingInformationStillRequiredContinued"
      ];

      return missingTemplateArray.includes(this.activeTemplate);
    },
    attachmentPasswordInput() {
      return this.activeTemplate == "pensionReportEmailQA";
    },
    bespokeEmail() {
      return this.activeTemplate == "moreInfoFromEmployer";
    },
    dateInput() {
      let dateTemplateArray = [
        "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
        "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumber",
        "loaCoveringEmailDataRequestProviderKnownPolicyNumber",
        "loaCoveringEmailDataRequestProviderWetSignature"
      ];

      return dateTemplateArray.includes(this.activeTemplate);
    },
    employmentSelect() {
      let employment = [];
      for (let i in this.getEmployments) {
        employment.push({
          id: this.getEmployments[i].name_of_employer,
          text: this.getEmployments[i].name_of_employer
        });
      }
      return employment;
    },
    setDefaultEmail() {
      return this.activeTemplate == "pensionReportEmailQA"
          ? "sandrassuranceqa@aviva.com"
          : this.getApplication?.email;
    },
    colStyleLeft() {
      return {
        "col-4": this.activeTemplateCheck(),
        "d-none": !this.activeTemplateCheck()
      };
    },
    colStyleRight() {
      return {
        "col-8": this.activeTemplateCheck(),
        "col-12": !this.activeTemplateCheck()
      };
    }
  },
  methods: {
    ...mapActions([
      "getDocuments",
      "getTracedPensionFocus",
      "previewEmail",
      "sendEmail"
    ]),
    ...mapMutations(["setLoading", "setNotification"]),
    handleSubMenuSelected(selected) {
      if (selected === 'send-document') {
        this.activeTemplate = null
      }
      this.subMenuSelected = selected;
    },
    async handleSendToUpdate(e) {
      let valid = await this.schema.isValid({
        email: e
      });
      this.sendTo = e;
      this.disableSend = !valid;
    },
    showPlaceEmpHistory(value) {
      if (value == "yes") {
        this.showPEH = true;
      } else {
        this.showPEH = false;
      }
    },
    showEmpDates(value) {
      if (value == "yes") {
        this.showED = true;
      } else {
        this.showED = false;
      }
    },
    showEmpInfo(value) {
      if (value == "yes") {
        this.showEI = true;
      } else {
        this.showEI = false;
      }
    },
    handleEmploymentSelected(value) {
      if (!this.employmentPlaces) {
        this.employmentPlaces = value;
      } else {
        this.employmentPlaces += ", " + value;
      }
    },
    handleEmploymentDates(value) {
      if (!this.employmentDates) {
        this.employmentDates = value;
      } else {
        this.employmentDates += ", " + value;
      }
    },
    handleEmploymentMoreInfo(value) {
      if (!this.employmentMoreInfo) {
        this.employmentMoreInfo = value;
      } else {
        this.employmentMoreInfo += ", " + value;
      }
    },
    getPreviewRequestData() {
      let form = new FormData(document.getElementById("dataForm"));
      let rejectedReason = [];
      let data = Object.fromEntries(form);

      if (data.contactDate) {
        data.contactDate = this.formatReadDate(data.contactDate);
      } else if (
          this.activeTemplate == "loaRejectedAvivaResolve" ||
          this.activeTemplate == "loaRejectedContactProvider"
      ) {
        data = {};
        form.forEach(item => rejectedReason.push(item));
        if (rejectedReason.length > 0) {
          rejectedReason = rejectedReason.filter(
              item => typeof item != "undefined" && item != ""
          );
          data.rejectedReason = rejectedReason;
        }
      }

      return {
        activeTemplate: this.activeTemplate,
        // email: this.sendTo || this.getApplication.email,
        tpuuid: this.$route.params.tpuuid,
        data
      };
    },
    async handleSend() {
      this.setLoading({
        bool: true
      });

      let response = await this.sendEmail(this.getPreviewRequestData());

      this.setLoading({
        bool: false
      });

      this.setNotification({
        show: true,
        type: response.data.data === 1 ? "success" : "error",
        message: "Sending email"
      });
    },
    async handleUpdate() {
      if (!this.$refs.emailIframe) return;
      this.disableSend =
          this.activeTemplate == "blankTemplate" &&
          this.getPreviewRequestData()?.data?.email == "";
      try {
        let response = await this.previewEmail(this.getPreviewRequestData());
        this.emailSubject = response.data.data.subject;
        this.disableSend = false;
        this.$refs.emailIframe.srcdoc = response.data.data.body;
      } catch (error) {
        this.disableSend = true;
        this.$refs.emailIframe.srcdoc = error.response.data.error;
        this.setNotification({
          show: true,
          type: "error",
          message: "Email data error!",
          content: this.formulateErrorString(error)
        });
      }
    },
    activeTemplateCheck() {
      let templateArray = [
        // "definedBenefits",
        "blankTemplate",
        "automateLetterGeneration",
        "definedBenefits",
        "definedBenefitsMarketing",
        "loaRejectedAvivaResolve",
        "loaRejectedContactProvider",
        // "loaRejectedNoPolicyNumber",
        "providerConfirmedRetirementIncomeOrFullCash",
        // "loaCoveringEmailDataRequestToProviderUnknown",
        "providerConfirmedTransferOut",
        "providerUnableToFindPolicy",
        // "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
        "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumber",
        "loaCoveringEmailDataRequestProviderKnownPolicyNumber",
        "loaCoveringEmailDataRequestProviderWetSignature",
        "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
        "searchUnsuccessful",
        // "missingInformation",
        // "missingInformationChaser",
        // "missingInformationChaserClosed",
        // "missingInformationReceived",
        "missingInformationStillRequiredClosed",
        "missingInformationStillRequiredContinued",
        "pensionReportEmail",
        "pensionReportEmailQA",
        "moreInfoFromEmployer",
        "noResponseTwoMonths"
      ];

      return templateArray.includes(this.activeTemplate);
    },
    handleSelected(value) {
      this.activeTemplate = value;
      setTimeout(() => {
        this.handleUpdate();
        this.checkAvailableFileTypes();
      }, 500);
    },
    checkAvailableFileTypes() {
      let requiredFileTypes =
          Object.fromEntries(this.getEmailTemplates.map(i => [i.id, i.required]))[ // getEmailTemplates
              this.activeTemplate
              ] || [];
      // Filter out any types that we already have and the rest are unavailable
      this.unavailableDocTypes = requiredFileTypes.filter(
          requiredType => !this.availableFileTypes.includes(requiredType)
      );
      if (this.unavailableDocTypes.length) {
        this.showDocumentError = true;
      } else {
        this.showDocumentError = false;
      }
    },
    async getAvailableFileTypes() {
      if (this.getFiles !== null) {
        this.availableFileTypes = this.getFiles.map(f => f.type);
      } else {
        let response = await this.getDocuments({
          type: "traced-pension",
          uuid: this.$route.params.tpuuid
        });
        this.availableFileTypes = response.data.data.map(f => f.type);
      }
    }
  },
  async beforeMount() {
    if (this.$route.query.parent == 'app' || this.$route.query.parent == 'emailapp') {
      return;
    }

    this.setLoading({
      bool: true
    });

    if (!this.getEmployments.data) {
      await this.getTracedPensionFocus(this.$route.params.tpuuid).catch(err =>
          console.log(err)
      );
    }

    this.getEmailTemplates.sort((a, b) => // getEmailTemplates
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    await this.getAvailableFileTypes();

    if (this.computedTemplate !== null) {
      this.handleSelected(this.computedTemplate);
    }

    this.setLoading({
      bool: false
    });
  }
};
</script>
<style lang="scss" scoped>
.email-manager {
  #emailIframe {
    width: 600px;
    height: 800px;
    background: rgb(255, 255, 255);
    outline-color: #d8d8d8;
    outline-width: 5px;
    outline-style: solid;
  }
}

.sub-menu {
  width: 100%;
  padding-left: 20px;
}
</style>
